/* eslint-disable react/display-name */
/* eslint-disable no-await-in-loop */

import * as smApi from '@gmini/sm-api-sdk'

import { Snackbar } from '@gmini/ui-kit'
import { declensionsOfNum } from '@gmini/utils'

import { EnqueueSnackbar } from 'notistack'
import { OmniSubscriptionService } from '@gmini/helpers/src/omniSubscriptionService/createOmniSubscriptionService.types'
import { omniSubscriptionService } from '@gmini/helpers'

import { ById, PreparedIssue, fetchNotLoadedIssuesFx } from '../../issue.store'
import { PROJECT_IS_NOT_SELECTED_ERROR } from '../../../constants'

import { getCreateOmniSubscriptionParams } from './converter'

export enum IssueSubscribeOperationType {
  CREATE = 'CREATE',
  REMOVE = 'REMOVE',
}

export type IssueSubscribeOperation = {
  checkedIds: number[]
  issuesMap: Map<number, PreparedIssue>
  userInfo: smApi.Auth.UserData | null
}

export type OperationConfig = {
  setPending: (value: boolean) => void
  errorMessage: string
  messages: [string, string, string]
  actionText: string
  filterFn: (params: IssueSubscribeOperation) => number[]
  processSubscription: (params: {
    id: string
    projectUrn?: string
    subscriptionId?: string
  }) => Promise<smApi.Omni.Subscription | null>
  formatSubscriptionUpdate: (
    data: Array<smApi.Omni.Subscription | null>,
  ) => Record<string, string>
}

export const getIssuesWithSubscriptions = async ({
  checkedIds,
  byId,
  projectUrn,
  subscriptionById,
}: {
  checkedIds: number[]
  byId: ById
  projectUrn: string
  subscriptionById: OmniSubscriptionService.SubscriptionById
}) => {
  const issues = await fetchNotLoadedIssuesFx({
    byId,
    ids: checkedIds,
    projectUrn,
  })

  const issuesMap = new Map(issues.map(issue => [issue.id, issue]))
  Object.values(subscriptionById).forEach(sub => {
    sub.attributes.forEach(({ attribute, value }) => {
      if (attribute !== 'issue.id') {
        return
      }

      const issue = issuesMap.get(Number(value))
      if (issue) {
        issuesMap.set(issue.id, {
          ...issue,
          subscriptionPublicId: sub.publicId,
        })
      }
    })
  })

  return issuesMap
}

export const showSubscribeOperationResult = ({
  success,
  count,
  closeSnackbar,
  enqueueSnackbar,
  errorMessage,
  config,
  snackbarDataTestId,
}: {
  success: boolean
  count: number
  closeSnackbar: (key: string) => void
  enqueueSnackbar: EnqueueSnackbar
  errorMessage: string
  config: OperationConfig
  snackbarDataTestId: string
}) => {
  if (!success) {
    enqueueSnackbar(errorMessage, { variant: 'error' })
    return
  }

  const { messages, actionText } = config

  enqueueSnackbar('', {
    content: (key: string) => (
      <Snackbar
        data-test-id={snackbarDataTestId}
        id={key}
        message={`${count} ${declensionsOfNum(
          count,
          messages,
        )} ${actionText} отслеживаемых`}
        onClose={() => closeSnackbar(key)}
      />
    ),
    variant: 'info',
  })
}

export const handleIssueSubscribeOperation = async ({
  selectedProject,
  checked,
  byId,
  subscriptionById,
  userInfo,
  closeSnackbar,
  enqueueSnackbar,
  config,
  snackbarDataTestId,
}: {
  selectedProject: smApi.Project | null
  checked: Record<string, boolean>
  byId: Record<string, PreparedIssue>
  subscriptionById: OmniSubscriptionService.SubscriptionById
  userInfo: smApi.Auth.UserData | null
  closeSnackbar: () => void
  enqueueSnackbar: EnqueueSnackbar
  config: OperationConfig
  snackbarDataTestId: string
}) => {
  if (!selectedProject) {
    throw new Error(PROJECT_IS_NOT_SELECTED_ERROR)
  }

  config.setPending(true)

  const checkedIds = Object.keys(checked).map(Number)
  const issuesMap = await getIssuesWithSubscriptions({
    checkedIds,
    byId,
    projectUrn: selectedProject.urn,
    subscriptionById,
  })

  const idsToProcess = config.filterFn({ checkedIds, issuesMap, userInfo })

  if (idsToProcess.length === 0) {
    showSubscribeOperationResult({
      success: false,
      count: 0,
      closeSnackbar,
      enqueueSnackbar,
      errorMessage: config.errorMessage,
      config,
      snackbarDataTestId,
    })
    config.setPending(false)
    return
  }

  const results: (smApi.Omni.Subscription | null)[] = []
  for (const id of idsToProcess) {
    try {
      const result = await config.processSubscription({
        id: id.toString(),
        projectUrn: selectedProject.urn,
        subscriptionId: byId[id]?.subscriptionPublicId,
      })
      results.push(result)
    } catch (error) {
      console.error(error)
    }
  }

  omniSubscriptionService.updateSubscriptionsMap(
    config.formatSubscriptionUpdate(results),
  )

  showSubscribeOperationResult({
    success: true,
    count: results.length,
    closeSnackbar,
    enqueueSnackbar,
    errorMessage: config.errorMessage,
    config,
    snackbarDataTestId,
  })
  config.setPending(false)
}

export const filterIssuesForSubscription = ({
  checkedIds,
  issuesMap,
  userInfo,
}: IssueSubscribeOperation) =>
  checkedIds.filter(key => {
    const issue = issuesMap.get(key)
    return (
      issue &&
      !issue.subscriptionPublicId &&
      issue.author !== userInfo?.id &&
      !issue.assignees.some(assignee => assignee.assigneeId === userInfo?.id)
    )
  })

export const filterIssuesForUnSubscription = ({
  checkedIds,
  issuesMap,
  userInfo,
}: IssueSubscribeOperation) =>
  checkedIds.filter(key => {
    const issue = issuesMap.get(key)
    return (
      issue &&
      issue.subscriptionPublicId &&
      issue.author !== userInfo?.id &&
      !issue.assignees.some(assignee => assignee.assigneeId === userInfo?.id)
    )
  })

type CreateSubscribeOperationConfigParams = {
  setPendingCreate: (value: boolean) => void
  setPendingRemove: (value: boolean) => void
}

export const createSubscribeOperationConfigs = ({
  setPendingCreate,
  setPendingRemove,
}: CreateSubscribeOperationConfigParams): Record<
  IssueSubscribeOperationType,
  OperationConfig
> => ({
  [IssueSubscribeOperationType.CREATE]: {
    setPending: setPendingCreate,
    errorMessage: subscriptionUnavailableText,
    messages: [
      'новое замечание добавлено',
      'новых замечания добавлены',
      'новых замечаний добавлены',
    ],
    actionText: 'в список',
    filterFn: filterIssuesForSubscription,
    processSubscription: params =>
      omniSubscriptionService.createSubscription(
        getCreateOmniSubscriptionParams({
          id: params.id,
          projectUrn: params.projectUrn!,
        }),
      ),
    formatSubscriptionUpdate: results =>
      results.reduce(
        (acc, sub) => ({
          ...acc,
          [sub?.attributes[0].value || '']: sub?.publicId || '',
        }),
        {},
      ),
  },
  [IssueSubscribeOperationType.REMOVE]: {
    setPending: setPendingRemove,
    errorMessage: unSubscriptionUnavailableText,
    messages: ['замечание убрано', 'замечания убраны', 'замечаний убраны'],
    actionText: 'из списка',
    filterFn: filterIssuesForUnSubscription,
    processSubscription: params =>
      omniSubscriptionService.removeSubscription({
        id: params.subscriptionId || '',
      }),
    formatSubscriptionUpdate: results =>
      results.reduce(
        (acc, sub) => (sub ? { ...acc, [sub.attributes[0].value]: '' } : acc),
        {},
      ),
  },
})

export const subscriptionUnavailableText =
  'Нельзя подписаться на замечания, где вы являетесь автором или ответственным, или подписка уже есть'
export const unSubscriptionUnavailableText =
  'Нельзя отписаться от замечаний, где вы являетесь автором или ответственным, или вы не подписаны'
