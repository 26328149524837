import { GalleryItem } from '../GalleryItem'

import { GalleryItemFile } from '../../molecules/GalleryItem/GalleryItem.types'

import { Container } from './Gallery.styled'

export type DataTestIdForGallery = {
  list: string
  item: string
}

type GalleryProps<F> = {
  fileList: F[]
  fileListChecked: Record<number, boolean>
  onCheckedFile?: (file: F) => void
  onDeleteFile?: (file: F) => void
  disabled?: boolean
  onDownloadFile?: (file: F) => void
  onOpenViewer?: (file: F) => void
  getImgSrc?: (file: F) => string | null | undefined
  isDeletable?: (file: F) => boolean
  dataTestIdFor?: DataTestIdForGallery
}

export const Gallery = <F extends GalleryItemFile>({
  fileList,
  fileListChecked,
  onCheckedFile,
  onDeleteFile,
  disabled,
  onDownloadFile,
  onOpenViewer,
  getImgSrc,
  isDeletable,
  dataTestIdFor,
}: GalleryProps<F>) => (
  <Container data-test-id={dataTestIdFor?.list}>
    {fileList
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((file, idx) => (
        <GalleryItem
          key={file.id}
          file={file}
          checked={fileListChecked[file.id]}
          onChecked={onCheckedFile}
          onDelete={onDeleteFile}
          onClickView={onOpenViewer}
          getImgSrc={getImgSrc}
          disabledDelete={disabled}
          onDownload={onDownloadFile}
          isDeletable={isDeletable}
          dataTestIdFor={{
            item: `${dataTestIdFor?.item}_${idx}`,
            checked: `${dataTestIdFor?.item}CheckedBtn_${idx}`,
            download: `${dataTestIdFor?.item}DownloadBtn_${idx}`,
            view: `${dataTestIdFor?.item}ViewBtn_${idx}`,
            delete: `${dataTestIdFor?.item}DeleteBtn_${idx}`,
          }}
        />
      ))}
  </Container>
)
