import {
  combine,
  createEffect,
  createEvent,
  createStore,
  merge,
  sample,
} from 'effector'

import * as api from '@gmini/ism-api-sdk'
import * as smApi from '@gmini/sm-api-sdk'
import * as DMApi from '@gmini/sm-api-sdk/lib/DMAPi'

import { clone } from 'ramda'

import {
  DateFilterItemsCode,
  IssueListFilterDeadlineOptionCode,
  filterDateEnrichment,
  getAttributesValueIds,
  omniSubscriptionService,
  sortByFieldEntity,
} from '@gmini/helpers'

import { FilterShowItemType } from '@gmini/components/src/molecules/FilterShow/FilterShow.types'

import { ZERO_SEARCH } from '../constants'

import { gStationDocumentManagementUrl } from '../config'

import {
  deleteFile,
  fetchContentFiles,
  startUploadFile,
  uploadFiles,
} from './file.store'

import { attributesService } from './attribute.store'
import { filterService } from './issueFilter.store'
import {
  fetchIssueTemplate,
  fetchIssueTemplateMostRecent,
  updateIssueTemplate,
} from './issueTemplate.action'
import { fetchIssueCommentList } from './organisms/Comments/model'
import { orderedFieldsXlsx } from './organisms/IssueList/constants'
import { matchIssueToFilters } from './organisms/IssueList/matchIssueToFilters'
import { filterDeadlineRangeByCode } from './organisms/IssueListFilterPanel/issueListDeadlineOptions'
import {
  fetchAllowedFilters,
  fetchAllowedFiltersPending$,
} from './organisms/IssueListFilterPanel/model'

const {
  appliedFilters: { appliedFilters$ },
} = filterService

export const fetchMostRecentIssue = api.Issue.fetchMostRecent.createContext()
export const fetchMostRecentIssuePending$ = fetchMostRecentIssue.pending$
export const fetchListIssue = api.Issue.fetchList.createContext()
export const fetchXlsxIssue = api.Issue.fetchXlsx.createContext()
export const fetchListIssueByIds = api.Issue.fetchListByIds.createContext()
export const fetchListIssuePending$ = fetchListIssue.pending$
export const fetchXlsxIssuePending$ = fetchXlsxIssue.pending$
export const fetchListIssueByIdsPending$ = fetchListIssueByIds.pending$
export const createIssue = api.Issue.create.createContext()
export const createIssuePending$ = createIssue.pending$

export const updateIssue = api.Issue.update.createContext()
export const updateIssuePending$ = updateIssue.pending$
export const changeStatus = api.Issue.changeStatus.createContext()
export const changeStatusPending$ = changeStatus.pending$

export const fetchIssueIds = api.Issue.fetchIssueIds.createContext()
export const fetchIssueIdsPending$ = fetchIssueIds.pending$

merge([
  fetchMostRecentIssue.doneData,
  fetchIssueTemplateMostRecent.doneData,
  fetchIssueTemplate.doneData,
  updateIssueTemplate.doneData,
  updateIssue.doneData,
]).watch(({ attributes }) => {
  const valueIds = getAttributesValueIds(attributes)

  if (!valueIds.length) {
    return
  }

  attributesService.fetchAttributeValues({
    valueIds,
  })
})

export const createIssueWithFiles = createEffect<
  { params: api.Issue.CreateIssueParams; files?: File[] },
  api.Issue.IssuePopulated
>()
export const createIssueWithFilesPending$ = createIssueWithFiles.pending

createIssueWithFiles.use(async ({ params, files }) => {
  const issue = await createIssue(params)

  if (!files?.length) {
    return issue
  }

  await uploadFiles({ issue, files })

  return issue
})

changeStatus.doneData.watch(({ id, version }) => {
  fetchIssueCommentList({ issueId: id, issueVersion: version })
})

sample({
  source: appliedFilters$,
  clock: merge([
    createIssue.doneData,
    updateIssue.doneData,
    changeStatus.doneData,
  ]),
  fn(filters, issue) {
    fetchAllowedFilters({
      projectUrn: issue.projectUrn,
      showNotRelevant: true,
    })

    if (!matchIssueToFilters(issue, filters)) {
      removeIssueFromIds({ id: issue.id, search: filters.filter })
    }
  },
})

export type FetchIssueListWithExtraDataParams = Omit<
  api.Issue.FetchListParams,
  | 'projectUrn'
  | 'createdDateRange'
  | 'updatedDateRange'
  | 'deadlineRange'
  | 'assignees'
> & {
  project: smApi.Project
  createdDateRange?: string[] | null
  updatedDateRange?: string[] | null
  createdDateCode?: DateFilterItemsCode | null
  updatedDateCode?: DateFilterItemsCode | null
  deadlineCode?: IssueListFilterDeadlineOptionCode | null
  assignees?: api.Assignee[]
  attributes?: api.Attribute[]
  show?: FilterShowItemType
}

export type FetchIssueListXLSXWithExtraDataParams = Omit<
  FetchIssueListWithExtraDataParams,
  'limit' | 'offset'
> & {
  fields: string[]
}

export const fetchIssueListWithExtraData = createEffect<
  FetchIssueListWithExtraDataParams,
  { total: number }
>()

fetchIssueListWithExtraData.use(
  async ({
    project,
    updatedDateRange,
    createdDateRange,
    updatedDateCode,
    createdDateCode,
    deadlineCode,
    assignees,
    ...otherProps
  }) => {
    const currentDate = new Date()

    const { enrichedUpdatedDateRange, enrichedCreatedDateRange } =
      filterDateEnrichment(currentDate, {
        updatedDateRange,
        createdDateRange,
        updatedDateCode,
        createdDateCode,
      })

    const enrichedDeadlineRange = deadlineCode
      ? filterDeadlineRangeByCode[deadlineCode]?.(currentDate)
      : null

    const issueListData = await fetchListIssue({
      ...otherProps,
      projectUrn: project.urn,
      updatedDateRange: enrichedUpdatedDateRange,
      createdDateRange: enrichedCreatedDateRange,
      deadlineRange: enrichedDeadlineRange,
      assignees,
    })

    if (
      gStationDocumentManagementUrl &&
      project?.sourceType === 'GStation' &&
      issueListData.total
    ) {
      fetchListLinkedIssueToFile({
        issues: issueListData.list.map(({ id }) => id),
      })
    }
    return issueListData
  },
)
export const fetchIssueListPending$ = createStore(false)
  .on(fetchIssueListWithExtraData, () => true)
  .on(fetchIssueListWithExtraData.done, () => false)
  .on(fetchIssueListWithExtraData.fail, () => false)

export const fetchIssueXlsxWithExtraData = createEffect<
  FetchIssueListXLSXWithExtraDataParams,
  unknown
>().use(
  async ({
    project,
    updatedDateRange,
    createdDateRange,
    updatedDateCode,
    createdDateCode,
    deadlineCode,
    fields,
    assignees,
    ...otherProps
  }) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const currentDate = new Date()

    const { enrichedUpdatedDateRange, enrichedCreatedDateRange } =
      filterDateEnrichment(currentDate, {
        updatedDateRange,
        createdDateRange,
        updatedDateCode,
        createdDateCode,
      })

    const enrichedDeadlineRange = deadlineCode
      ? filterDeadlineRangeByCode[deadlineCode]?.(currentDate)
      : null

    await fetchXlsxIssue({
      ...otherProps,
      projectUrn: project.urn,
      updatedDateRange: enrichedUpdatedDateRange,
      createdDateRange: enrichedCreatedDateRange,
      deadlineRange: enrichedDeadlineRange,
      assignees,
      fields: orderedFieldsXlsx,
      timezone,
    })
  },
)

export const fetchListLinkedIssueToFile =
  DMApi.DMFile.fetchListLinkedIssueToFile.createContext()
export const fetchListLinkedIssueToFilePending$ =
  fetchListLinkedIssueToFile.pending$

export const fetchIssueHistory = api.Issue.fetchHistoryChanges.createContext()
export const fetchIssueHistoryPending$ = fetchIssueHistory.pending$

export const issueHistory$ = createStore<api.Issue.HistoryChanges | null>(
  null,
).on(fetchIssueHistory.doneData, (_state, result) => result)

fetchIssueHistory.doneData.watch(historyChanges => {
  const historyAttributeValueIdList = [
    ...new Set(
      historyChanges?.list.reduce((acc: number[], historyItem) => {
        historyItem.items.forEach(item => {
          if (api.Issue.isHistoryItemAttribute(item)) {
            item.addedAttributes.forEach(({ valueIds }) =>
              acc.push(...valueIds),
            )
            item.deletedAttributes.forEach(({ valueIds }) =>
              acc.push(...valueIds),
            )
          }
        })

        return acc
      }, []),
    ),
  ]

  attributesService.fetchAttributeValues({
    valueIds: historyAttributeValueIdList,
  })
})

fetchMostRecentIssue.doneData.watch(({ id, version }) => {
  fetchContentFiles({ issueId: id, issueVersion: version })
})

export const addIssueToList = createEvent<api.Issue.Issue>()
export const resetIssueList = createEvent()

export type LinkedEntity = {
  moduleName: string
  entityName: string
  link: string
}

export type PreparedIssue = (api.Issue.Issue | api.Issue.IssuePopulated) & {
  linkedEntity?: LinkedEntity | null
  subscriptionPublicId?: string
  allowedActions?: api.Issue.IssuePopulated['allowedActions'] | undefined
  answer?: api.Issue.IssuePopulated['answer']
}

export type ById = {
  [id: string]: PreparedIssue
}

export const issuePending$ = combine(
  [
    createIssuePending$,
    fetchListIssuePending$,
    fetchListIssueByIdsPending$,
    fetchListLinkedIssueToFilePending$,
    changeStatusPending$,
    fetchAllowedFiltersPending$,
  ],
  pendings => pendings.some(Boolean),
)

export const fetchIssuesWithSubscription = createEffect<
  string,
  { total: number }
>()

fetchIssuesWithSubscription.use(async (projectUrn: string) => {
  resetIssueList()
  const subs = await omniSubscriptionService.fetchList({
    projectUrn,
    resourceName: smApi.Omni.ResourceName.ISSUE,
    moduleId: smApi.Omni.ModuleId.ISSUE_MANAGEMENT,
  })

  const ids = subs.reduce((acc: number[], sub) => {
    const { attribute, value } = sub.attributes[0]
    return attribute === 'issue.id' ? [...acc, Number(value)] : acc
  }, [])
  const res = ids.length
    ? await fetchListIssueByIds({
        projectUrn,
        ids,
      })
    : { list: [], total: 0 }
  return res
})

export const updatePreparedIssue = createEvent<PreparedIssue>()

updatePreparedIssue.watch(issue => {
  fetchAllowedFilters({
    projectUrn: issue.projectUrn,
    showNotRelevant: true,
  })
})

export const fetchNotLoadedIssuesFx = createEffect<
  { ids: number[]; byId: Record<number, PreparedIssue>; projectUrn: string },
  PreparedIssue[]
>().use(async ({ ids, byId, projectUrn }) => {
  const loadedIssues = ids
    .map(id => byId[id])
    .filter(issue => issue && issue.id !== undefined && issue.id !== null)

  if (loadedIssues.length === ids.length) {
    return loadedIssues
  }

  const issues = await fetchListIssueByIds({
    ids: ids.filter(id => !byId[id]?.id),
    projectUrn,
  })

  const issuesMap = new Map(issues.list.map(issue => [issue.id, issue]))

  return ids.map(id => ({ ...(byId[id] || {}), ...(issuesMap.get(id) || {}) }))
})

const byId$ = createStore<ById>({})
  .on(fetchListIssue.doneData, (state, result) => {
    const next = { ...state }
    result.list.forEach(ch => (next[ch.id] = { ...next[ch.id], ...ch }))
    return next
  })
  .on(fetchListIssueByIds.doneData, (state, result) => {
    const next = { ...state }
    result.list.forEach(ch => (next[ch.id] = { ...next[ch.id], ...ch }))
    return next
  })
  .on(
    merge([
      updateIssue.doneData,
      fetchMostRecentIssue.doneData,
      changeStatus.doneData,
      startUploadFile.doneData,
      createIssue.doneData,
    ]),
    (state, result) => ({
      ...state,
      [result.id]: { ...state[result.id], ...result },
    }),
  )
  .on(omniSubscriptionService.fetchList.doneData, (state, result) => {
    const nextState = clone(state)
    return result.reduce((acc, sub) => {
      const { attribute, value } = sub.attributes[0]
      return attribute === 'issue.id'
        ? {
            ...acc,
            [value]: {
              ...(state[value] || {}),
              subscriptionPublicId: sub.publicId,
            },
          }
        : acc
    }, nextState)
  })
  .on(omniSubscriptionService.updateSubscriptionsMap, (state, result) => {
    const nextState = clone(state)
    return Object.keys(result).reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          ...(state[key] || {}),
          subscriptionPublicId: result[key],
        },
      }),
      nextState,
    )
  })
  .on([deleteFile.doneData], (state, result) => ({
    ...state,
    [result.issueId]: {
      ...state[result.issueId],
      version: result.issueVersion,
    },
  }))
  .on(fetchListLinkedIssueToFile.done, (state, { params, result }) => {
    const next = clone(state)

    params.issues.forEach(issueId => {
      const linkedEntity = result.issues.find(
        ({ externalId }) => externalId === issueId,
      )
      return (next[issueId] = {
        ...next[issueId],
        linkedEntity: linkedEntity
          ? {
              moduleName: 'G-station',
              entityName: linkedEntity.file.name,
              link: linkedEntity.file.webview,
            }
          : null,
      })
    })

    return next
  })
  .on(updatePreparedIssue, (state, result) => ({
    ...state,
    [result.id]: { ...state[result.id], ...result },
  }))

export const addIssueToIds = createEvent<{
  search: string
  id: number
  sortByFieldName?: string | null
  sortByOperator?: api.SortByOperator | null
}>()
export const addIssueToIdsWithIssueById = sample({
  clock: addIssueToIds,
  source: byId$,
  fn: (issueById, params) => ({
    issueById,
    params,
  }),
})
export const removeIssueFromIds = createEvent<{ search: string; id: number }>()

type IdsBySearchValue = Record<string | symbol, number[] | undefined>

const ids$ = createStore<IdsBySearchValue>({})
  .on(fetchListIssue.done, (state, { params, result }) => {
    const next = { ...state }
    const search = params.filter || ZERO_SEARCH
    next[search] = [
      ...new Set([
        ...(state[search] || []),
        ...result.list.map(({ id }) => id),
      ]),
    ]
    return next
  })
  .on(fetchListIssueByIds.done, (state, { result }) => {
    const next = { ...state }
    const search = ZERO_SEARCH
    next[search] = [
      ...new Set([
        ...(state[search] || []),
        ...result.list.map(({ id }) => id),
      ]),
    ]
    return next
  })
  .on(addIssueToIdsWithIssueById, (state, { issueById, params }) => {
    const next = clone(state)

    const search = params.search || ZERO_SEARCH

    const nextIdsCurrentSearch = [
      ...new Set([params.id, ...(next[search] || [])]),
    ]

    if (params.sortByFieldName && params.sortByOperator) {
      const issueList = nextIdsCurrentSearch.map(id => issueById[id])

      const sortedIssueList = sortByFieldEntity(issueList, {
        sortByFieldName: params.sortByFieldName as keyof PreparedIssue,
        sortByOperator: params.sortByOperator,
      })

      next[search] = sortedIssueList.map(({ id }) => id)
    } else {
      next[search] = nextIdsCurrentSearch
    }

    return next
  })
  .on(removeIssueFromIds, (state, result) => {
    const next = clone(state)

    const search = result.search || ZERO_SEARCH

    next[search] = next[search]?.filter(id => id !== result.id) || []

    return next
  })
  .on(resetIssueList, state => ({}))

export const totalIssues$ = createStore<number | null>(null).on(
  fetchListIssue.doneData,
  (state, result) => result.total,
)

export const issuesList$ = combine({
  byId$,
  ids$,
  totalIssues$,
})

deleteFile.doneData.watch(({ issueId }) =>
  fetchMostRecentIssue({ id: issueId }),
)

uploadFiles.doneData.watch(({ issue }) => fetchMostRecentIssue(issue))
