import { Tooltip } from '@gmini/ui-kit'

import {
  SelectViewCompactIcon,
  SelectViewExpandedIcon,
  SelectViewItem,
  SelectViewWrapper,
} from './SelectView.styled'

export type DataTestIdForSelectView = {
  compact: string
  expanded: string
}

type SelectViewProps = {
  isCompact: boolean
  onSelect: (isCompact: boolean) => void
  dataTestIdFor?: DataTestIdForSelectView
}

export const SelectView = ({
  isCompact,
  onSelect,
  dataTestIdFor,
}: SelectViewProps) => (
  <SelectViewWrapper>
    <Tooltip
      title={'Компактный вид'}
      styleContent={{
        width: 'max-content',
      }}
      noMaxWidth
    >
      <SelectViewItem isSelected={isCompact}>
        <SelectViewCompactIcon
          data-test-id={dataTestIdFor?.compact}
          isSelected={isCompact}
          onClick={() => onSelect(true)}
        />
      </SelectViewItem>
    </Tooltip>
    <Tooltip
      title={'Развернутый вид'}
      styleContent={{
        width: 'max-content',
      }}
      noMaxWidth
    >
      <SelectViewItem isLast isSelected={!isCompact}>
        <SelectViewExpandedIcon
          data-test-id={dataTestIdFor?.expanded}
          isSelected={!isCompact}
          onClick={() => onSelect(false)}
        />
      </SelectViewItem>
    </Tooltip>
  </SelectViewWrapper>
)
