export enum IssuePermissions {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  VIEW_ROLE_COMP = 'VIEW_ROLE_COMP',
  VIEW_COMP = 'VIEW_COMP',
  EDIT_ASSIGNEE = 'EDIT_ASSIGNEE',
  EDIT_AUTHOR = 'EDIT_AUTHOR',
  VIEW_EDIT_ALL = 'VIEW_EDIT_ALL',
}

interface GetIssuePermissionsParams {
  projectUrn?: string | null
  userInfo?: {
    permissions?: {
      [projectUrn: string]: {
        [scope: string]: string[]
      }
    }
  } | null
}

export const getIssuePermissions = (
  params: GetIssuePermissionsParams,
): Map<IssuePermissions, boolean> => {
  const permissions =
    params.userInfo?.permissions?.[params.projectUrn || '']?.ISSUE || []

  return new Map(
    Object.values(IssuePermissions).map(permission => [
      permission,
      permissions.includes(permission),
    ]),
  )
}
