import styled from 'styled-components'

import { FieldLabel } from '@gmini/components'

export const EmptyContainer = styled.div`
  height: 60px;
`

export const CopyIssueTemplateContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const ProjectContainer = styled.div`
  display: flex;
  width: 528px;
  height: 100%;
  padding: 24px;
  background: #f4f4f8;
  flex-direction: column;
  gap: 16px;
`

export const ProjectFieldLabel = styled(FieldLabel)`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 3, 53, 1);
  margin-bottom: 4px;
`

export const TemplatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 410px;
  gap: 16px;
`

export const TemplatesContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`

export const TemplatesContainerHeaderTitle = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: rgba(0, 3, 53, 1);
`

export const TemplatesContainerHeaderCount = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: rgba(128, 129, 154, 1);
`

export const TemplatesItems = styled.div`
  display: flex;
  flex-direction: column;
`

export const TemplatesItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  gap: 16px;
  padding-right: 8px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
`

export const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const WarningText = styled.div`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 3, 53, 1);
`

export const FieldContainer = styled.div`
  width: 100%;
`

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  padding: 24px 32px 24px 32px;
`

export const SimpleScrollbar = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  max-height: 63vh;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #e5e7f1;
    border-radius: 30px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }
`
