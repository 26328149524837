import { BadResult, isJsendBadResultError } from '@gmini/api-call-service'
import * as api from '@gmini/ism-api-sdk'
import { merge, Event } from 'effector'
import { prop } from 'ramda'

export const fetchIssueTemplateMostRecent =
  api.IssueTemplate.fetchMostRecent.createContext()
export const fetchIssueTemplate = api.IssueTemplate.fetch.createContext()
export const deleteIssueTemplate = api.IssueTemplate.remove.createContext()
export const updateIssueTemplate = api.IssueTemplate.update.createContext()
export const createIssueTemplate = api.IssueTemplate.create.createContext()
export const copyIssueTemplates = api.IssueTemplate.copy.createContext()
export const fetchIssueTemplateIds =
  api.IssueTemplate.fetchIssueTemplateIds.createContext()
export const fetchIssueTemplateIdsPending$ = fetchIssueTemplateIds.pending$
export const fetchIssueTemplatesByIds =
  api.IssueTemplate.fetchIssueTemplatesByIds.createContext()

const badResult: Event<BadResult> = merge([
  api.IssueTemplate.fetchMostRecent.failData,
  api.IssueTemplate.fetch.failData,
  api.IssueTemplate.remove.failData,
  api.IssueTemplate.update.failData,
  api.IssueTemplate.create.failData,
  api.IssueTemplate.copy.failData,
])

const error = badResult.filter({ fn: isJsendBadResultError })
type MessageMapCode = 'FAILED_COPY_ISSUE_TEMPLATE'
type MessageMap = Record<MessageMapCode, string>

const messageMap: MessageMap = {
  FAILED_COPY_ISSUE_TEMPLATE: 'Ошибка копирования. Попробуйте еще раз.',
}

const unexpectedText =
  'Непредвиденная ошибка. Пожалуйста, обратитесь к разработчикам'

const formatErrorMessage = (message: string, code: string | null) =>
  `${message}`

export const issueTemplateError = error
  .map(prop('message'))
  .map(message =>
    formatErrorMessage(
      messageMap[message as keyof typeof messageMap] || unexpectedText,
      message,
    ),
  )
