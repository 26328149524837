export enum IssueTemplatePermissions {
  VIEW_OWN = 'VIEW_OWN',
  VIEW_FOREIGN = 'VIEW_FOREIGN',
  EDIT_OWN = 'EDIT_OWN',
  EDIT_FOREIGN = 'EDIT_FOREIGN',
}

interface GetIssueTemplatePermissionsParams {
  projectUrn?: string | null
  userInfo?: {
    permissions?: {
      [projectUrn: string]: {
        [scope: string]: string[]
      }
    }
  } | null
}

export const getIssueTemplatePermissions = (
  params: GetIssueTemplatePermissionsParams,
): Map<IssueTemplatePermissions, boolean> => {
  const permissions =
    params.userInfo?.permissions?.[params.projectUrn || '']?.ISSUE_TEMPLATE ||
    []

  return new Map(
    Object.values(IssueTemplatePermissions).map(permission => [
      permission,
      permissions.includes(permission),
    ]),
  )
}
