import { ArrowDown, ArrowUp, Container, Text } from './ShowAll.styled'

type ShowAllProps = {
  hideButtonText: string
  showButtonText: string
  isHidden: boolean
  onSwitch?: () => void
  dataTestId?: string
}

export const ShowAll = ({
  hideButtonText,
  showButtonText,
  onSwitch,
  isHidden,
  dataTestId,
}: ShowAllProps) => (
  <Container onClick={onSwitch} data-test-id={dataTestId}>
    <Text>{isHidden ? showButtonText : hideButtonText}</Text>
    {isHidden ? <ArrowDown /> : <ArrowUp />}
  </Container>
)
