import styled from 'styled-components'
import { Info, RoundedCross } from '@gmini/ui-kit'

type WrapperProps = {
  isSmall?: boolean
}

export const Wrapper = styled.div`
  background: rgba(13, 153, 102, 1);
  width: 100%;
  height: 100%;
  padding: ${(props: WrapperProps) =>
    props.isSmall ? '7px 24px 9px 24px' : '12px 24px 14px 24px'};
  box-shadow: 0px 4px 20px rgba(53, 59, 96, 0.4);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  gap: 0 24px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px 0;
  margin: auto 0;
`

export const Message = styled.div`
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
`

export const InfoText = styled.div`
  color: rgba(255, 255, 255, 1);
  max-width: 360px;
  line-height: 19px;
  letter-spacing: 0;
`

export const InfoTextWrapper = styled.div`
  display: flex;
  gap: 0 5px;
`

export const InfoIcon = styled(Info)`
  min-width: 20px;
  min-height: 20px;
`

export const CloseButton = styled(RoundedCross)`
  min-width: 24px;
  min-height: 24px;
  cursor: pointer;
`
